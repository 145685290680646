import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`14th March 2020`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">{`You can now use `}<inlineCode parentName="li">{`UUID`}</inlineCode>{` as a parameter in your annotated service. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2573"
        }}>{`#2573`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2577"
        }}>{`#2577`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`public class MyAnnotatedService {
    @Get("/user/by-uuid/{uuid}")
    public User findUserByUuid(@Param UUID uuid) { ... }
}
`}</code></pre>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`LogLevel.OFF`}</inlineCode>{` and `}<inlineCode parentName="li">{`LogLevel.log(Logger, String, Object...)`}</inlineCode>{` have been added. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2532"
        }}>{`#2532`}</a></li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`ClosedSessionException`}</inlineCode>{` and `}<inlineCode parentName="li">{`ClosedStreamException`}</inlineCode>{` now have a specific cause or message to help you understand why a connection or a stream has been closed. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2580"
        }}>{`#2580`}</a></li>
      <li parentName="ul">{`A once-per-thread warning message will now be logged, as well as throwing an exception, when a user pushes a context incorrectly. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2583"
        }}>{`#2583`}</a></li>
      <li parentName="ul">{`Some common socket exceptions raised by annotated services are not logged anymore by default to reduce the amount of less useful log messages. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2572"
        }}>{`#2572`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`ConcurrencyLimitingClient`}</inlineCode>{` does not fail with an 'illegal context' error under load anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2579"
        }}>{`#2579`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`RetryingClient`}</inlineCode>{` and `}<inlineCode parentName="li">{`RetryingRpcClient`}</inlineCode>{` handles negative `}<inlineCode parentName="li">{`System.nanoTime()`}</inlineCode>{` values correctly now. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2584"
        }}>{`#2584`}</a></li>
      <li parentName="ul">{`Fixed a bug where `}<inlineCode parentName="li">{`RetryingClient`}</inlineCode>{` and `}<inlineCode parentName="li">{`RetryingRpcClient`}</inlineCode>{` do not complete a request when `}<inlineCode parentName="li">{`RetryStrategy`}</inlineCode>{` or `}<inlineCode parentName="li">{`RetryStrategyWithContent`}</inlineCode>{` throws an exception. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2581"
        }}>{`#2581`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`EventLoopCheckingFuture`}</inlineCode>{` and its subtypes do not warn about blocking operation anymore when they are done already. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2564"
        }}>{`#2564`}</a></li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Dropwizard Metrics 4.1.4 → 4.1.5`}</li>
      <li parentName="ul">{`gRPC 1.27.2 → 1.28.0`}</li>
      <li parentName="ul">{`Netty 4.1.46 → 4.1.47`}</li>
      <li parentName="ul">{`RxJava 2.2.18 → 2.2.19`}</li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['anuraaga', 'codefromthecrypt', 'ikhoon', 'kojilin', 'minwoox', 'renaudb', 'tobias-', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      